'use client';

import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import { useTranslations } from 'next-intl';
import type { ButtonProps } from '@mui/material/Button';
import Button from 'components/Button';
import Paper from 'components/Paper';
import ChannelAvatar from 'components/Avatar/Channel';
import Typography from 'components/Typography';
import { Link } from 'utils/navigation';
import { ReactComponent as AdIcon } from 'public/assets/icons/ad.svg';
import styles from './Promotion.module.scss';
type PromotionCommonProps = {
  title: string;
  description: string;
  action?: {
    label: NextIntlKeys;
  } & ButtonProps;
  footer?: {
    label: string;
    value: string;
  };
};
type PromotionConditionalProps =
// |
{
  channel: {
    image: string | null;
    verified: boolean;
  };
  image?: never;
};
// | {
//     image: string;
//     channel?: never;
//   };

export type PromotionProps = PromotionCommonProps & PromotionConditionalProps;
const Promotion: React.FC<PromotionProps> = ({
  title,
  description,
  image = '',
  channel,
  action,
  footer
}) => {
  const t = useTranslations();
  const {
    label,
    ...buttonProps
  } = action || {};
  const imageContent = channel ? <ChannelAvatar size="96" name={title} src={channel.image} alt={title} verified={channel.verified} context="paper" /> : <Image src={image} alt={title} width={144} height={160} className={styles.imageNews} />;
  return <Paper className={styles.root} data-sentry-element="Paper" data-sentry-component="Promotion" data-sentry-source-file="Promotion.tsx">
      <div className={classnames(styles.image, {
      [styles.imageChannel]: !!channel
    })}>
        {imageContent}
      </div>
      <div className={styles.content}>
        <Typography variant="h8" className={styles.maxLines2} color="primary" data-sentry-element="Typography" data-sentry-source-file="Promotion.tsx">
          {title}
        </Typography>
        <div className={styles.description}>
          <Typography variant="paragraph10" className={styles.maxLines2} data-sentry-element="Typography" data-sentry-source-file="Promotion.tsx">
            {description}
          </Typography>
          {action && <Button variant="primary" size="medium" className={styles.action} {...buttonProps} component={Link}>
              {t(action.label)}
            </Button>}
          {footer && <div className={styles.footer}>
              <Typography variant="h12">{footer.label}</Typography>
              <Typography variant="label10" color="primary">
                {footer.value}
              </Typography>
            </div>}
        </div>
      </div>
      <AdIcon className={styles.adIcon} data-sentry-element="AdIcon" data-sentry-source-file="Promotion.tsx" />
    </Paper>;
};
export default Promotion;