'use client';

import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import Slider, { Settings } from '@ant-design/react-slick';
import breakpoints from 'constants/breakpoints';
import Promotion, { PromotionProps } from 'components/Promotion';
import styles from './Promotions.module.scss';
interface ClientSidePromotionsProps {
  posts: PromotionProps[];
  sliderId: string;
}
const ClientSidePromotions: React.FC<ClientSidePromotionsProps> = ({
  posts,
  sliderId
}) => {
  const [sliderClassName, setSliderClassName] = useState<string>(styles.slider);
  const sliderSettings = useMemo<Settings>(() => ({
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    variableWidth: false,
    focusOnSelect: true,
    initialSlide: 1,
    responsive: [{
      breakpoint: breakpoints.tablet,
      settings: {
        centerPadding: '0',
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true
      }
    }, {
      breakpoint: breakpoints.mobile,
      settings: {
        centerPadding: '0',
        slidesToShow: 1,
        centerMode: true,
        variableWidth: false
      }
    }],
    onInit: () => {
      document.querySelector(`#${sliderId}`)?.classList.add(styles.hidden);
      setSliderClassName(classnames(styles.slider, styles.visible));
    }
  }), []);
  return (
    // @ts-ignore
    <Slider {...sliderSettings} className={sliderClassName} data-sentry-element="Slider" data-sentry-component="ClientSidePromotions" data-sentry-source-file="ClientSidePromotions.tsx">
      {posts.map(promotion => <Promotion {...promotion} key={promotion.title} />)}
    </Slider>
  );
};
export default ClientSidePromotions;