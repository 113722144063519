import(/* webpackMode: "eager" */ "/app/components/Divider/Divider.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(catalogue)/Navigation/Navigation.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Promotion/Promotion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Tab/Tab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/LoginPanel/LoginPanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/Promotions/ClientSidePromotions.tsx");
;
import(/* webpackMode: "eager" */ "/app/features/Promotions/Promotions.module.scss");
;
import(/* webpackMode: "eager" */ "/app/features/PageLayout/PageLayout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
